/* 1. Extra Small (Phones in portrait mode) */
@media (max-width: 480px) {
  .carousel-item {
    min-height: 20vh !important;
    background-size: cover;
  }

  .carousel-item .MuiTypography-h1 {
    font-size: 1.4rem !important;
  }

  .carousel-item .MuiTypography-body2 {
    font-size: 0.8rem !important;
  }
}

/* 2. Small Mobile Screens */
@media (min-width: 481px) and (max-width: 600px) {
  .carousel-item {
    min-height: 25vh !important;
    background-size: cover;
  }

  .carousel-item .MuiTypography-h1 {
    font-size: 1.6rem !important;
  }

  .carousel-item .MuiTypography-body2 {
    font-size: 0.9rem !important;
  }
}

/* 3. Small Tablets (600px - 768px) */
@media (min-width: 601px) and (max-width: 768px) {
  .carousel-item {
    min-height: 30vh !important;
    background-size: cover;
  }

  .carousel-item .MuiTypography-h1 {
    font-size: 1.8rem !important;
  }

  .carousel-item .MuiTypography-body2 {
    font-size: 1rem !important;
  }
}

/* 4. Medium Tablets (769px - 900px) */
@media (min-width: 769px) and (max-width: 900px) {
  .carousel-item {
    min-height: 42vh !important;
    background-size: cover;
  }

  .carousel-item .MuiTypography-h1 {
    font-size: 2rem !important;
  }

  .carousel-item .MuiTypography-body2 {
    font-size: 1.1rem !important;
  }

  .carousel-item button {
    font-size: 0.85rem !important;
    width: 7rem !important;
  }
}

/* 5. Laptops (901px - 1200px) */
@media (min-width: 901px) and (max-width: 1200px) {
  .carousel-item {
    min-height: 50vh !important;
    background-size: cover;
  }

  .carousel-item .MuiTypography-h1 {
    font-size: 2.2rem !important;
  }

  .carousel-item .MuiTypography-body2 {
    font-size: 1.2rem !important;
  }
}

/* 6. Desktops (1201px - 1440px) */
@media (min-width: 1201px) and (max-width: 1440px) {
  .carousel-item {
    min-height: 65vh !important;
    background-size: cover;
  }

  .carousel-item .MuiTypography-h1 {
    font-size: 2.5rem !important;
  }

  .carousel-item .MuiTypography-body2 {
    font-size: 1.3rem !important;
  }
}

/* 7. Large Screens (1441px and above) */
@media (min-width: 1441px) {
  .carousel-item {
    min-height: 83.2vh !important;
    background-size: cover;
  }

  .carousel-item .MuiTypography-h1 {
    font-size: 3rem !important;
  }

  .carousel-item .MuiTypography-body2 {
    font-size: 1.5rem !important;
  }
}
