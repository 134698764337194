.navbar {
    background-color: #ffffff;
    position: sticky;
    top: 36px;
    left: 0;
    right: 50px;
    z-index: 1000;
    box-shadow: 0 4px 2px -2px gray;
}

html, body {
    overflow-x: hidden;
    width: 100%;
}

.container {
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.navbar .navbar-brand img {
    width: 500px;
    max-width: 100%;
    height: auto;
        margin: 10px 0;
}
.navbar .navbar-brand {
    margin-left: 80px;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: 0;
    margin-left: -1px;
    display: none;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.dropdown-toggle::after {
    margin-left: 0.25rem;
}

.navbar .navbar-brand {
    margin-left: 5px;
}

.navbar {
    height: 80px;
}

.navbar .nav-link {
    font-size: 0.9rem;
}

.navbar-nav .nav-item:hover {
    position: relative;
}

.navbar-toggler {
    margin-top: -55px;
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon {
    background-color: white;
}

.navbar-nav .nav-item:hover::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #008bf9;
}

.navbar-nav .nav-item .nav-link {
    color: #666666;
    font-size: 0.875rem;
    position: relative;
    text-transform: capitalize;
    white-space: nowrap;
    transition: color 0.3s ease;
}

.navbar .dropdown:hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.navbar .dropdown-menu {
    display: none;
    transition: all 0.3s ease-in-out;
}

.navbar-nav .nav-item .nav-link.active,
.navbar-nav .nav-item .dropdown-item.active {
    color: #008bf9 !important;
}

.navbar-nav .nav-item .dropdown-item.active {
    color: #008bf9 !important;
    background-color: transparent !important;
}

.collapse {
    transition: height 0.3s ease;
}

.collapse.show {
    display: block !important;
}

.navbar-nav .nav-item.active .nav-link {
    color: #008bf9 !important;
    font-weight: bold;
}

@media (max-width: 991px) {
    .navbar .navbar-brand img {
        width: 70%;
        height: auto;
        margin-top: 10px;
        margin-left: 50px;
        margin-bottom: 10px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .navbar-collapse {
        background-color: #ffffff;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .navbar .navbar-brand img {
        position: sticky;
        width: 70%;
        height: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 50px;
    }

    .navbar-toggler-icon {
        margin-top: 50px;
    }

    .navbar-collapse {
        background-color: #ffffff;
    }
}

@media (max-width: 480px) {
    .navbar .navbar-brand img {
        width: 70%;

    }

    .navbar-toggler {
        margin-top: -75px;
    }

    .navbar-toggler-icon {
        width: 30px;
        height: 30px;
    }

    .navbar-collapse {
        background-color: #ffffff;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .navbar .navbar-brand img {
        width: 70%;
        margin-top: 2px;
    }

    .navbar-toggler {
        margin-top: -90px;
        margin-left: -30px;
    }

    .navbar-toggler-icon {
        width: 35px;
        height: 35px;
    }

    .navbar-collapse {
        background-color: #ffffff;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .navbar .navbar-brand img {
        width: 70%;
        margin-top: 2px;
    }

    .navbar-toggler {
        margin-top: -50px;
    }

    .navbar-toggler-icon {
        width: 40px;
        height: 40px;
    }

    .navbar-collapse {
        background-color: #ffffff;
    }
}

@media (min-width: 992px) {
    .navbar .navbar-brand img {
        width: 250px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .navbar-toggler {
        margin-top: -55px;
    }

    .navbar-toggler-icon {
        width: 45px;
        height: 45px;
    }

    .navbar-collapse {
        background-color: #ffffff;
    }
}

@media (min-width: 360px) and (max-width: 740px) {
    .navbar .navbar-brand img {
        width: 100px;
        padding-right: 30px;
        margin-right: 25px;
    }

    .navbar-toggler {
        margin-top: -85px;
        padding: 1px;
        margin-left: 2px;
    }

    .navbar-toggler-icon {
        width: 35px;
        height: 30px;
    }

    .navbar-collapse {
        background-color: #ffffff;
    }
}

@media (min-width: 617px) and (max-width: 767px) {
    .navbar .navbar-brand img {
        width: 50px;
        padding-right: 30px;
        margin-right: 25px;
    }

    .navbar-toggler {
        margin-top: -10px;
        padding: 1px;
        margin-left: 2px;
    }

    .navbar-toggler-icon {
        width: 35px;
        height: 30px;
    }

    .navbar-collapse {
        background-color: #ffffff;
    }
}

@media (min-width: 450px) and (max-width: 616px) {
        .navbar .navbar-brand img {
        width: 50px;
        padding-right: 30px;
        margin-right: 25px;
    }

    .navbar-toggler {
        margin-top: -100px;
        padding: 1px;
        margin-left: 2px;
    }

    .navbar-toggler-icon {
        width: 35px;
        height: 30px;
    }

    .navbar-collapse {
        background-color: #ffffff;
    }
}

@media (min-width: 1024px) and (max-width: 1366px) {

    .navbar .navbar-brand img {
        width: 50px;
    }

    .container.nav-item {
        margin-left: -75px;
    }

    .container {
        margin-left: -10px;
    }

    .navbar-nav .nav-link {
        font-size: 0.5rem;
    }

    .navbar-toggler {
        margin-top: -50px;
    }

    .navbar-collapse {
        background-color: #ffffff;
    }

    .navbar .navbar-nav {
        padding-left: 10px;
        padding-right: 10px;
    }
}